import { Table, Descriptions } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { QueryRecall, RecallEvaluationResults } from '../../types/types';

const RecallDisplay = ({ evaluationResults }: { evaluationResults: RecallEvaluationResults }) => {
  const columns: ColumnsType<QueryRecall> = [
    {
      title: 'Query',
      dataIndex: 'query',
      key: 'query',
    },
    {
      title: 'Recall',
      dataIndex: 'recall',
      key: 'recall',
      render: (recall) => `${(recall * 100).toFixed(2)}%`,
    },
  ];

  return (
    <div>
      <Descriptions
        bordered
        column={1}
        labelStyle={{ width: '50%' }}
        contentStyle={{ width: '50%' }}
      >
        <Descriptions.Item label="Mean Recall">
          {(evaluationResults.meanRecall * 100).toFixed(2)}%
        </Descriptions.Item>
        <Descriptions.Item label="Median Recall">
          {(evaluationResults.medianRecall * 100).toFixed(2)}%
        </Descriptions.Item>
        <Descriptions.Item label="Minimum Recall">
          {(evaluationResults.minRecall * 100).toFixed(2)}%
        </Descriptions.Item>
        <Descriptions.Item label="Maximum Recall">
          {(evaluationResults.maxRecall * 100).toFixed(2)}%
        </Descriptions.Item>
        <Descriptions.Item label="K Value">{evaluationResults.k}</Descriptions.Item>
      </Descriptions>
      <Table
        columns={columns}
        dataSource={evaluationResults.recalls}
        pagination={false}
        scroll={{ y: 300 }}
      />
    </div>
  );
};

export default RecallDisplay;
