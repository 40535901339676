import { useState } from 'react';
import { TextProduct } from '../../../types/types';
import { Button } from 'antd';
import { HeartFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { setSelectedProduct, setShowRecommendations } from '../../../slices/recommendationsSlice';
import './textProductCard.css';

const TextProductCard = ({
  product,
  onFavourite,
}: {
  product: TextProduct;
  onFavourite: Function;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleFavourite = (type: string) => {
    if (type === 'text') {
      onFavourite(product.name, type);
    } else {
      console.log(`Invalid favourites request for ${product} and type ${type}`);
    }
  };

  const handleRecommend = () => {
    dispatch(setSelectedProduct(product));
    dispatch(setShowRecommendations(true));
  };
  return (
    <div id={product.id} className={`text-product-card ${isLoaded ? 'loaded' : ''}`}>
      <div className="favouriting-button">
        <Button
          shape="circle"
          icon={<HeartFilled />}
          onClick={() => {
            handleFavourite('text');
          }}
        />
      </div>
      <div className="text-card">
        <div className="text-presentation">
          <h3 className="text-product-title">{product.name}</h3>
          {product.display_image !== null ? (
            <div className="text-description-w-display">
              <img src={product.display_image} alt={product.name} />
              <p className="text-product-desc text-description-clamped">{product.description}</p>
            </div>
          ) : (
            <p className="text-product-desc">{product.description}</p>
          )}
        </div>
        <Button
          type="primary"
          onClick={() => {
            handleRecommend();
          }}
        >
          Recommend
        </Button>
      </div>
    </div>
  );
};

export default TextProductCard;
