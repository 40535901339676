import { useEffect, useState } from 'react';
import { CustomFilter } from '../../types/types';
import { Button, Descriptions, Input, Modal, Space } from 'antd';
import { fetchCustomFilter } from '../../services/customFilterService';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { setShowCreateStyle } from '../../slices/customStyleSlice';

const CreateStyleModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const open = useSelector((state: RootState) => state.customStyle.showCreateStyle);
  const item = useSelector((state: RootState) => state.customStyle.referenceProduct);

  const [styleName, setStyleName] = useState<string>('');
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [styleComplete, setStyleComplete] = useState<boolean>(false);
  const [styleTemplate, setStyleTemplate] = useState<CustomFilter | null>(null);

  useEffect(() => {
    if (item === null) return;
    setStyleName(item.name);
    setStyleComplete(false);
    setRequestLoading(false);
    setStyleTemplate(null);
  }, [item]);

  const handleCreateStyle = () => {
    const fetchStyle = async () => {
      setRequestLoading(true);
      setStyleTemplate(null);
      setStyleComplete(false);
      let style = null;
      if (item !== null && item.image_url != null && item.image_url) {
        style = await fetchCustomFilter({ name: styleName, imageURL: item.image_url });
      }
      setRequestLoading(false);
      setStyleComplete(true);
      setStyleTemplate(style);
    };
    fetchStyle();
  };

  useEffect(() => {
    if (styleTemplate === null) return;
    const storedFilters = localStorage.getItem('customFilters');
    if (storedFilters) {
      let currentFilters = JSON.parse(storedFilters);
      currentFilters = currentFilters.filter(
        (filter: CustomFilter) => filter.name !== styleTemplate.name,
      );
      currentFilters.push(styleTemplate);
      localStorage.setItem('customFilters', JSON.stringify(currentFilters));
    } else {
      localStorage.setItem('customFilters', JSON.stringify([styleTemplate]));
    }
  }, [styleTemplate]);

  const handleClose = () => {
    dispatch(setShowCreateStyle(false));
  };

  return (
    <Modal width={1000} title="Create Style" open={open} onCancel={handleClose} footer={null}>
      <Space.Compact direction="horizontal">
        <Input
          placeholder="Style Name"
          value={styleName}
          onChange={(e) => setStyleName(e.target.value)}
          style={{ width: '400px' }}
        />
        <Button type="primary" onClick={handleCreateStyle} loading={requestLoading}>
          Create Style
        </Button>
      </Space.Compact>
      {styleComplete && styleTemplate !== null ? (
        <div style={{ paddingTop: 10 }}>
          {/* <div>Filter '{styleTemplate.name}' created</div>
                    <div>Style Terms: {styleTemplate.customFilterTemplate}</div> */}
          <Descriptions>
            <Descriptions.Item label="Filter Name">{styleTemplate.name}</Descriptions.Item>
            <Descriptions.Item label="Style Terms">
              {styleTemplate.customFilterTemplate}
            </Descriptions.Item>
          </Descriptions>
        </div>
      ) : null}
    </Modal>
  );
};

export default CreateStyleModal;
