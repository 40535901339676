import { API_BASE_URL } from '../constants';

interface RecommendationTreeRequest {
  itemID: string;
  layers: number;
  children: number;
  demoID: string;
}
export interface RecommendationTreeNode {
  id: string;
  name: string;
  parent?: string; // Optional since the root won't have a parent
  image_url: string;
  children: RecommendationTreeNode[]; // Added to handle nested structure
}

export default class RecommendationTreeService {
  private eventSource: EventSource | null = null;

  connect(
    request: RecommendationTreeRequest,
    callback: (data: RecommendationTreeNode) => void,
  ): void {
    const params = new URLSearchParams(request as any).toString();
    const url = `${API_BASE_URL}/recommendation_tree?${params}`;
    this.eventSource = new EventSource(url);

    this.eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        callback(data);
      } catch (error) {
        console.error('Error parsing SSE data:', error);
      }
    };

    this.eventSource.addEventListener('stream-end', () => {
      this.close();
    });

    this.eventSource.onerror = (error) => {
      if (this.eventSource) {
        console.error(`EventSource failed with readyState: ${this.eventSource.readyState}`);
      } else {
        console.error('EventSource failed:', error);
      }
      this.close();
    };
  }

  close(): void {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }
}
