import { Button, Modal } from 'antd';
import { ScoreModifier } from '../../types/types';
import { useState } from 'react';
import { SlidersFilled } from '@ant-design/icons';
import ScoreModifierSelector from './scoreModifierSelector';
import './scoreModifierSelectorModal.css';

type ScoreModifierSelectorModalProps = {
  scoreModifierAttributes: string[];
  onChange: (scoreModifiers: ScoreModifier[]) => void;
};

const ScoreModifierSelectorModal = ({
  scoreModifierAttributes,
  onChange,
}: ScoreModifierSelectorModalProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button className="modifier-modal-button" type="default" onClick={() => setOpen(true)}>
        {<SlidersFilled />} Score Modifiers
      </Button>
      <Modal
        title="Score Modifiers"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
      >
        <div>
          <ScoreModifierSelector
            scoreModifierAttributes={scoreModifierAttributes}
            onChange={onChange}
          />
        </div>
      </Modal>
    </>
  );
};

export default ScoreModifierSelectorModal;
