import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../constants';
import { DemoSettings, indexTypeEnum } from '../types/types';

interface DemoSelectorState {
  demoID: string;
  stagingDemoID: string;
  valid: boolean;
  demoSettings: DemoSettings;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const defaultDemoSettings: DemoSettings = {
  showMoreOf: true,
  showLessOf: true,
  showCustomInstructions: true,
  showFavourites: true,
  showSearchSettings: true,
  showStyle: true,
  showWebsiteSearchExtractor: true,
  showChat: true,
  showCart: true,
  availableStyles: [],
  indexType: indexTypeEnum.MULTIMODAL_IMAGE_TEXT,
  deduplication: false,
  defaultSearchType: 'semantic',
  filterableAttributes: null,
  filterConfig: null,
  scoreModifierAttributes: null,
  gridType: 'cardGrid',
};

const initialState: DemoSelectorState = {
  demoID: '',
  stagingDemoID: '',
  valid: false,
  status: 'idle',
  demoSettings: defaultDemoSettings,
  error: null,
};

export const checkDemoID = createAsyncThunk('demoSelector/checkDemoID', async (demoID: string) => {
  const response = await fetch(API_BASE_URL + '/check_demo_id', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ demoID: demoID }),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data.valid;
});

export const fetchDemoSettings = createAsyncThunk(
  'demoSelector/fetchDemoSettings',
  async (demoID: string) => {
    const response = await fetch(API_BASE_URL + '/get_demo_settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ demoID: demoID }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  },
);

export const demoSelectorSlice = createSlice({
  name: 'demoSelector',
  initialState,
  reducers: {
    setDemoID: (state, action: PayloadAction<string>) => {
      state.demoID = action.payload;
    },
    setStagingDemoID: (state, action: PayloadAction<string>) => {
      state.stagingDemoID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkDemoID.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(checkDemoID.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.status = 'succeeded';
        state.valid = action.payload;
        state.error = null;
      })
      .addCase(checkDemoID.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Could not fetch products';
      })
      .addCase(fetchDemoSettings.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchDemoSettings.fulfilled, (state, action: PayloadAction<DemoSettings>) => {
        state.status = 'succeeded';
        state.demoSettings = action.payload;
        state.error = null;
      })
      .addCase(fetchDemoSettings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Could not fetch products';
      });
  },
});

export const { setDemoID, setStagingDemoID } = demoSelectorSlice.actions;

export default demoSelectorSlice.reducer;
