import React, { useEffect, useRef, useState } from 'react';
import { Tree, RawNodeDatum } from 'react-d3-tree';
import { useSelector } from 'react-redux';
import RecommendationTreeService, {
  RecommendationTreeNode,
} from '../../services/recommendationTreeService';
import { RootState } from '../../store/store';
import { Progress } from 'antd';
import { ImageProduct } from '../../types/types';

const transformToRawNodeDatum = (node: RecommendationTreeNode): RawNodeDatum => {
  const rawNodeDatum: RawNodeDatum = {
    name: node.name,
    attributes: {
      image_url: node.image_url,
    },
    children: node.children ? node.children.map(transformToRawNodeDatum) : [],
  };
  return rawNodeDatum;
};

interface CustomNodeElementProps {
  nodeDatum: RawNodeDatum;
  toggleNode: () => void;
}

const renderCustomNodeElement = ({ nodeDatum, toggleNode }: CustomNodeElementProps) => {
  const imageSize = 120;
  const imageOffset = imageSize / 2;
  const borderSize = 1; // Size of the border
  // Adjust the rectangle size to include the border in the total dimensions
  const totalSize = imageSize + borderSize * 2;
  const totalOffset = totalSize / 2;
  return (
    <g>
      <defs>
        <clipPath id="roundedClipPath">
          <rect
            x={`-${totalOffset}`}
            y={`-${totalOffset}`}
            width={totalSize}
            height={totalSize}
            rx="12"
            ry="12"
          />
        </clipPath>
      </defs>
      <rect
        x={`-${totalOffset}`}
        y={`-${totalOffset}`}
        width={totalSize}
        height={totalSize}
        rx="10" // Keep the border rounded
        ry="10"
        fill="white" // Set the fill to white
        stroke="black" // Border color
        strokeWidth={borderSize} // Border size
      />
      <image
        href={(nodeDatum.attributes?.image_url as string) || ''}
        x={`-${imageOffset}`}
        y={`-${imageOffset}`}
        width={`${imageSize}px`}
        height={`${imageSize}px`}
        clipPath="url(#roundedClipPath)"
        onClick={toggleNode}
      />
    </g>
  );
};

interface RecommendationTreeProps {
  displayed: boolean;
}

const RecommendationTree: React.FC<RecommendationTreeProps> = ({ displayed }) => {
  const layers = 8;
  const children = 2;
  const [treeData, setTreeData] = useState<RawNodeDatum | null>(null);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const treeContainerRef = useRef<HTMLDivElement>(null);

  const recommendations = useSelector((state: RootState) => state.recommendations);
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);

  useEffect(() => {
    if (!recommendations.selectedProduct || displayed === false) {
      return;
    }
    const recommendationTreeService = new RecommendationTreeService();
    setTreeData(null);
    setLoadingProgress(0);
    recommendationTreeService.close();
    let layersReceived = 0;

    const request = {
      itemID: recommendations.selectedProduct.id,
      layers: layers,
      children: children,
      demoID: demoID,
    };

    recommendationTreeService.connect(request, (data) => {
      const updatedTreeData = transformToRawNodeDatum(data);
      updatedTreeData.name = recommendations.selectedProduct?.name || '';
      updatedTreeData.attributes = {
        image_url: (recommendations.selectedProduct as ImageProduct).image_url || '',
      };

      if (updatedTreeData) {
        setTreeData(updatedTreeData);
      }

      layersReceived++;
      setLoadingProgress((layersReceived / layers) * 100);
    });

    return () => {
      recommendationTreeService.close();
    };
  }, [recommendations.selectedProduct, displayed]);

  const [treeElement, setTreeElement] = useState<JSX.Element | null>(null);
  useEffect(() => {
    if (treeData === null) {
      return;
    }
    const treeElement = (
      <Tree
        dataKey={`${loadingProgress}`}
        data={treeData}
        translate={translate}
        renderCustomNodeElement={renderCustomNodeElement}
        orientation={'vertical'}
        scaleExtent={{ min: 0.01, max: 3 }}
        separation={{ siblings: 1, nonSiblings: 1 }}
      />
    );
    setTreeElement(treeElement);
  }, [treeData]);

  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const updateTranslate = () => {
      if (treeContainerRef.current) {
        const centerX = treeContainerRef.current.offsetWidth / 2;
        const centerY = 50;
        setTranslate({ x: centerX, y: centerY });
      }
    };
    updateTranslate();
    window.addEventListener('resize', updateTranslate);
    return () => window.removeEventListener('resize', updateTranslate);
  }, [displayed]);

  if (!displayed) {
    return <></>;
  }

  return (
    <div>
      <Progress percent={Math.round(loadingProgress)} />

      <div ref={treeContainerRef} id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
        {treeData !== null && treeElement}
      </div>
    </div>
  );
};

export default RecommendationTree;
