import { useDispatch } from 'react-redux';
import { ImageProduct, TextProduct, VideoProduct } from '../../types/types';
import { AppDispatch } from '../../store/store';
import { setSelectedProduct } from '../../slices/recommendationsSlice';
import { Button, Image } from 'antd';
import { FALLBACK_IMAGE } from '../../constants';

interface ItemGridProps {
  selectedId: string;
  showRecommendButton?: boolean;
}

interface ImageGridProps extends ItemGridProps {
  products: ImageProduct[];
}

interface TextGridProps extends ItemGridProps {
  products: TextProduct[];
}

interface VideoGridProps extends ItemGridProps {
  products: VideoProduct[];
}

export const ImageGrid = ({ selectedId, products, showRecommendButton = true }: ImageGridProps) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className="image-grid">
      {products.map((product) => (
        <div key={product.id} className="recommended-item">
          <div className="image-container">
            <Image
              className="recommended-item-image"
              src={product.image_url}
              alt={product.name}
              width={300}
              height={300}
              fallback={FALLBACK_IMAGE}
            />
          </div>
          <div className="recommended-item-name">{product.name}</div>

          {showRecommendButton && (
            <Button
              onClick={() => dispatch(setSelectedProduct(product))}
              disabled={product.id === selectedId}
            >
              Recommend
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export const TextGrid = ({ selectedId, products, showRecommendButton = true }: TextGridProps) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className="text-grid">
      {products.map((product) => (
        <div key={product.id} className="recommended-text-item">
          <div className="text-container">
            <h3 className="recommended-item-name">{product.name}</h3>
            {product.display_image !== null ? (
              <div className="recommended-description-w-display">
                <img src={product.display_image} alt={product.name} />
                <p className="recommended-item-description recommended-description-clamped">
                  {product.description}
                </p>
              </div>
            ) : (
              <p className="recommended-item-description">{product.description}</p>
            )}
            {/* <p className="recommended-item-description">{product.description}</p> */}
          </div>
          {showRecommendButton && (
            <Button
              onClick={() => dispatch(setSelectedProduct(product))}
              disabled={product.id === selectedId}
            >
              Recommend
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export const VideoGrid = ({ selectedId, products, showRecommendButton = true }: VideoGridProps) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className="video-grid">
      {products.map((product) => (
        <div key={product.id} className="recommended-item">
          <div className="video-container">
            <video
              className="recommended-item-video"
              src={product.video_url}
              width={300}
              height={300}
              controls
            />
          </div>
          <div className="recommended-item-name">{product.name}</div>

          {showRecommendButton && (
            <Button
              onClick={() => dispatch(setSelectedProduct(product))}
              disabled={product.id === selectedId}
            >
              Recommend
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};
