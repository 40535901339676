import { useState } from 'react';
import { Button, Modal } from 'antd';
import RecallEvaluator from './recallEvaluator';

const RecallEvaluationModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        Recall Evaluation
      </Button>
      <Modal
        title="Recall Evaluation"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        width={'70%'} // Set the width here, for example, 800px
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <RecallEvaluator />
      </Modal>
    </div>
  );
};

export default RecallEvaluationModal;
