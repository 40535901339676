import { VideoProduct } from '../../../types/types';
import { Button } from 'antd';
import { HeartFilled, ShoppingCartOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { setSelectedProduct, setShowRecommendations } from '../../../slices/recommendationsSlice';
import './videoProductCard.css';
import { addClicked } from '../../../slices/liveRecommendationsSlice';

const VideoProductCard = ({
  product,
  onFavourite,
  onAddToCart,
}: {
  product: VideoProduct;
  onFavourite: (content: VideoProduct, type: string) => void;
  onAddToCart: (content: VideoProduct, type: string) => void;
}) => {
  const { showFavourites, showCart } = useSelector(
    (state: RootState) => state.demoSelector.demoSettings,
  );

  const dispatch = useDispatch<AppDispatch>();

  const handleFavourite = (type: string) => {
    onFavourite(product, type);
  };

  const handleAddToCart = (type: string) => {
    onAddToCart(product, type);
  };

  const handleRecommend = () => {
    dispatch(setSelectedProduct(product));
    dispatch(setShowRecommendations(true));
  };

  const handleImageClick = () => {
    dispatch(addClicked(product));
  };

  return (
    <div id={product.id} className={'video-card'}>
      <div className="favouriting-button">
        {showFavourites && (
          <Button
            shape="circle"
            icon={<HeartFilled />}
            onClick={() => {
              handleFavourite('video');
            }}
          />
        )}
      </div>
      <div className="atc-button">
        {showCart && (
          <Button
            shape="circle"
            icon={<ShoppingCartOutlined />}
            onClick={() => {
              handleAddToCart('video');
            }}
          />
        )}
      </div>
      <div className="video-card">
        <video width="320" height="320" controls src={product.video_url} onClick={handleImageClick}>
          Your browser does not support the video tag.
        </video>
        <div className="video-meta">
          <div className="video-title">{product.name}</div>
          {product.price && <div className="video-price">{`$${product.price}`}</div>}
        </div>
        <div className="video-actions">
          <Button
            type="primary"
            onClick={() => {
              handleRecommend();
            }}
          >
            Recommend
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VideoProductCard;
