import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../constants';
import {
  Product,
  TextProduct,
  SimilarRecommendationsRequest,
  CompletementaryRecommendationsRequest,
} from '../types/types';

interface ResultsState {
  showRecommendations: boolean;
  selectedProduct: Product | TextProduct | null;
  recommendations: Product[] | TextProduct[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ResultsState = {
  showRecommendations: false,
  selectedProduct: null,
  recommendations: [],
  status: 'idle',
  error: null,
};

export const fetchSimilarRecommendations = createAsyncThunk(
  'products/fetchSimilarRecommendations',
  async (params: SimilarRecommendationsRequest) => {
    const response = await fetch(API_BASE_URL + '/recommend_similar_marqo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        itemID: params.product.id,
        demoID: params.demoID,
        limit: params.limit,
        diversity: params.diversity,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.results;
  },
);

export const fetchComplementaryRecommendations = createAsyncThunk(
  'products/fetchComplementaryRecommendations',
  async (params: CompletementaryRecommendationsRequest) => {
    const response = await fetch(API_BASE_URL + '/recommend_complementary_marqo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        itemID: params.product.id,
        terms: params.terms,
        demoID: params.demoID,
        limit: params.limit,
        advancedSettings: params.advancedSettings,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.results;
  },
);

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    setShowRecommendations(state, action: PayloadAction<boolean>) {
      state.showRecommendations = action.payload;
    },
    setSelectedProduct(state, action: PayloadAction<Product>) {
      state.selectedProduct = action.payload;
    },
    clearRecommendations(state) {
      state.selectedProduct = null;
      state.recommendations = [];
      state.status = 'idle';
      state.error = null;
      state.showRecommendations = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSimilarRecommendations.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.recommendations = [];
      })
      .addCase(fetchSimilarRecommendations.fulfilled, (state, action: PayloadAction<Product[]>) => {
        state.status = 'succeeded';
        state.recommendations = action.payload;
        state.error = null;
      })
      .addCase(fetchSimilarRecommendations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Could not fetch recommendations';
        state.recommendations = [];
      })
      .addCase(fetchComplementaryRecommendations.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.recommendations = [];
      })
      .addCase(
        fetchComplementaryRecommendations.fulfilled,
        (state, action: PayloadAction<Product[]>) => {
          state.status = 'succeeded';
          state.recommendations = action.payload;
          state.error = null;
        },
      )
      .addCase(fetchComplementaryRecommendations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Could not fetch recommendations';
        state.recommendations = [];
      });
  },
});

export const { clearRecommendations, setSelectedProduct, setShowRecommendations } =
  recommendationsSlice.actions;

export default recommendationsSlice.reducer;
