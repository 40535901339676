import './contactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-us">
      <h1>No Demo Found</h1>
      <div>Looking for a demo? Contact us below.</div>
      <a href="https://marqo.ai/book-demo">Contact Us</a>
    </div>
  );
};
export default ContactUs;
