import { API_BASE_URL } from '../constants';
import { RecallEvaluationRequest, RecallEvaluationResults } from '../types/types';

export const fetchRecallEvaluation = async (params: RecallEvaluationRequest) => {
  const response = await fetch(API_BASE_URL + '/evaluate_recall', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      queries: params.queries,
      efSearch: params.efSearch,
      limit: params.limit,
      demoID: params.demoID,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data: RecallEvaluationResults = await response.json();
  return data;
};
