import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { RootState } from '../../store/store';
import { Button, Input, Progress, Space, Tag } from 'antd';
import { Product } from '../../types/types';
import WebsiteExtractionSearchService from '../../services/websiteExtractionSearchService';
import WebsiteExtractionResults from './websiteExtractionResults';

import './websiteExtraction.css';

const WebsiteExtraction = () => {
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>('');
  const [nQueries, setNQueries] = useState<number>(0);
  const [nQueriesCompleted, setNQueriesCompleted] = useState<number>(0);
  const [extractedQueries, setExtractedQueries] = useState<string[]>([]);
  const [searchProgress, setSearchProgress] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);

  useEffect(() => {
    if (nQueries === 0) {
      setSearchProgress(0);
      return;
    }
    const newProgress = Math.round((nQueriesCompleted / nQueries) * 100);
    setSearchProgress(newProgress);
  }, [nQueries, nQueriesCompleted]);

  const handleSubmit = () => {
    if (url === '') {
      return;
    }
    const websiteExtractionSearchService = new WebsiteExtractionSearchService();
    setLoading(true);
    setSearchProgress(0);
    setSearchResults([]);
    setIsError(false);
    setNQueries(0);
    setNQueriesCompleted(0);
    setCurrentStep('');
    setExtractedQueries([]);
    websiteExtractionSearchService.close();

    const request = {
      url: url,
      demoID: demoID,
    };

    ReactGA.event('execute_website_extraction', {
      url: url,
      demo_id: demoID,
    });

    websiteExtractionSearchService.connect(request, (data) => {
      setCurrentStep(data.step);
      setNQueries(data.nQueries);
      setNQueriesCompleted(data.nQueriesCompleted);
      setSearchResults(data.results);
      setExtractedQueries(data.queries);
      setIsError(data.error);
    });
    setLoading(false);
    return () => {
      websiteExtractionSearchService.close();
    };
  };

  return (
    <div className="website-extractor">
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Space.Compact block>
          <Input placeholder="Enter URL" value={url} onChange={(e) => setUrl(e.target.value)} />
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            Process
          </Button>
        </Space.Compact>

        {currentStep !== '' && (
          <>
            <Progress percent={searchProgress} status={!isError ? undefined : 'exception'} />
            <div>Current Step: {currentStep}</div>
          </>
        )}
        <div>
          {extractedQueries.map((query) => (
            <Tag key={query} color="blue">
              {query}
            </Tag>
          ))}
        </div>
        <div>
          <iframe src={url} width="100%" height="300px" />
        </div>
        <WebsiteExtractionResults results={searchResults} loading={loading} />
      </Space>
    </div>
  );
};

export default WebsiteExtraction;
