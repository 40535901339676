import { useState } from 'react';
import { Modal, Button } from 'antd';
import StyleManager from './styleManager';

const StyleManagerModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setIsVisible(true)}>Open Style Manager</Button>
      <Modal
        title="Style Manager"
        open={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={null}
        width={1000}
      >
        <StyleManager />
      </Modal>
    </>
  );
};

export default StyleManagerModal;
