import { useEffect, useState } from 'react';
import { Header } from 'antd/es/layout/layout';
import { Button, Drawer, Space } from 'antd';
import DemoSelector from '../demoSelector/selectDemo';
import './appHeader.css';
import logo from '../../assets/logo.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import AdvancedSettingsModal from '../advancedSettings/advancedSettingsModal';
import RecallEvaluationModal from '../recallEvaluation/recallEvaluationModal';

const AppHeader = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const isValidDemoId = useSelector((state: RootState) => state.demoSelector.valid);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setDrawerVisible(false);
    }
  }, [isMobile]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <Header className="header-container" style={{ display: 'flex', alignItems: 'center' }}>
      <img className="marqo-logo-img" src={logo} alt="logo" />
      {isMobile ? (
        <>
          <Button className="mobile-burger-btn" type="primary" onClick={showDrawer}>
            Setup
          </Button>
          <Drawer
            title="Setup"
            placement="right"
            // closable={false}
            onClose={onClose}
            open={drawerVisible}
          >
            <Space direction="vertical">
              <DemoSelector />
              {isValidDemoId && <AdvancedSettingsModal />}
            </Space>
          </Drawer>
          <div className="desktop-components">
            {isValidDemoId && <AdvancedSettingsModal />}
            <DemoSelector />
          </div>
        </>
      ) : (
        <>
          {isValidDemoId && (
            <div className="advanced-model-container">
              <div className="advanced-modals">
                <AdvancedSettingsModal />
                <RecallEvaluationModal />
              </div>
            </div>
          )}
          <div className="demo-selector">
            <DemoSelector />
          </div>
        </>
      )}
    </Header>
  );
};

export default AppHeader;
