import { API_BASE_URL } from '../constants';
import { CustomFilterRequest, CustomFilter } from '../types/types';

export const fetchCustomFilter = async (params: CustomFilterRequest) => {
  const response = await fetch(API_BASE_URL + '/custom_filter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      name: params.name,
      imageURL: params.imageURL,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data: CustomFilter = await response.json();
  return data;
};
