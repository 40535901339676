import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { ImageProduct } from '../../types/types';
import { clearClicked, fetchLiveRecommendations } from '../../slices/liveRecommendationsSlice';
import { useEffect } from 'react';
import { Image } from 'antd';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './liveRecommendations.css';
import Loading from '../loading/loading';

const LiveRecommendations = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { clicked, recommendations } = useSelector((state: RootState) => state.liveRecommendations);
  const { demoID, status } = useSelector((state: RootState) => state.demoSelector);
  const maxLiveRecommendationsHistory = useSelector(
    (state: RootState) => state.advancedSettings.maxLiveRecommendationsHistory,
  );

  const isLoading = status === 'loading';

  useEffect(() => {
    if (clicked.length === 0) {
      return;
    }
    dispatch(
      fetchLiveRecommendations({
        itemIDs: clicked.map((product) => product.id),
        demoID: demoID,
        limit: 20,
        maxItems: maxLiveRecommendationsHistory,
      }),
    );
  }, [clicked]);

  useEffect(() => {
    dispatch(clearClicked());
  }, [demoID]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="live-recommendations-container">
      <h4>Recommendations from Clicks</h4>
      {isLoading && <Loading />}
      {clicked.length === 0 ? (
        <p>Click on a product image to see recommendations</p>
      ) : (
        <div className="live-recommendations">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            ssr={false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="transform 1s ease"
            transitionDuration={1000}
            containerClass="live-recommendation-carousel"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {recommendations.map((product) => (
              <div key={product.id}>
                <Image
                  width={200}
                  height={150}
                  src={(product as ImageProduct).image_url}
                  alt={product.name}
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default LiveRecommendations;
