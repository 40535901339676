import { Button, Modal } from 'antd';
import { useState } from 'react';
import Chat from './chat';

const ChatModal = () => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <Button type="primary" onClick={() => setShow(true)}>
        Open Chat
      </Button>
      <Modal title="Chat" visible={show} onCancel={() => setShow(false)} footer={null} width={1200}>
        <Chat />
      </Modal>
    </div>
  );
};

export default ChatModal;
