import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import resultsReducer from '../slices/resultsSlice';
import favouritesReducer from '../slices/favouritesSlice';
import searchSettingsSlice from '../slices/searchSettingsSlice';
import customInstructionsSlice from '../slices/customInstructionsSlice';
import searchStyleSlice from '../slices/searchStyleSlice';
import demoSelectorSlice from '../slices/demoSelectorSlice';
import advancedSettingsSlice from '../slices/advancedSettingsSlice';
import recommendationsSlice from '../slices/recommendationsSlice';
import customStyleSlice from '../slices/customStyleSlice';
import liveRecommendationsSlice from '../slices/liveRecommendationsSlice';
import cartSlice from '../slices/cartSlice';

const rootReducer = combineReducers({
  results: resultsReducer,
  favourites: favouritesReducer,
  searchSettings: searchSettingsSlice,
  customInstructions: customInstructionsSlice,
  searchStyle: searchStyleSlice,
  demoSelector: demoSelectorSlice,
  advancedSettings: advancedSettingsSlice,
  recommendations: recommendationsSlice,
  customStyle: customStyleSlice,
  liveRecommendations: liveRecommendationsSlice,
  cart: cartSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch;

export default store;
