import { API_BASE_URL } from '../constants';
import { ChatMessageRequest, Message } from '../types/types';

export const fetchChatMessage = async (params: ChatMessageRequest) => {
  const response = await fetch(API_BASE_URL + '/chat', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      conversation: params.conversation,
      demoID: params.demoID,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data: Message = await response.json();
  return data;
};
