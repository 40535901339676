import React, { useState } from 'react';
import { RecallEvaluationResults } from '../../types/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { fetchRecallEvaluation } from '../../services/recallEvaluationService';
import TextArea from 'antd/es/input/TextArea';
import { Button, Col, Input, Row } from 'antd';
import ReactGA from 'react-ga4';
import './recallEvaluator.css';
import Loading from '../loading/loading';
import RecallDisplay from './recalls';

const RecallEvaluator = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [queries, setQueries] = useState<string[]>([]);
  const [evalResults, setEvalResults] = useState<RecallEvaluationResults | null>(null);
  const [limit, setLimit] = useState<number>(10);
  const [efSearch, setEfSearch] = useState<number>(2000);
  const [efSearchError, setEfSearchError] = useState<boolean>(false);

  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);

  const handleSubmitForEval = async () => {
    setLoading(true);
    try {
      const evaluation = await fetchRecallEvaluation({ queries, efSearch, limit, demoID });
      setEvalResults(evaluation);
      ReactGA.event('execute_recall_evaluation', {
        queries: JSON.stringify(queries),
        ef_search: efSearch,
        limit: limit,
        demo_id: demoID,
      });
    } catch (error) {
      console.log('Error in fetching evaluation:', error);
      setEvalResults(null);
    }
    setLoading(false);
  };

  const handleQueriesChange = (e: any) => {
    setQueries(e.target.value.split('\n'));
  };

  const onLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newLimit = parseInt(e.target.value);
    if (newLimit > 150) {
      newLimit = 150;
    } else if (newLimit < 0) {
      newLimit = 1;
    }

    setLimit(newLimit);
  };

  const onEfSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newEfSearch = parseInt(e.target.value);

    if (newEfSearch < 0) {
      newEfSearch = 0;
    }

    if (newEfSearch < limit || Number.isNaN(newEfSearch)) {
      setEfSearchError(true);
    } else {
      setEfSearchError(false);
    }

    setEfSearch(newEfSearch);
  };

  return (
    <>
      <div className="recall-evaluator">
        <TextArea rows={15} onChange={handleQueriesChange} />
        <div className="recall-eval-results">
          {loading ? (
            <Loading />
          ) : evalResults ? (
            <>
              <RecallDisplay evaluationResults={evalResults} />
            </>
          ) : (
            <div>Please write some queries separated by new lines and submit them.</div>
          )}
        </div>
      </div>
      <div className="recall-eval-params">
        <Row>
          <Col span={6}>
            <div>Limit:</div>
          </Col>
          <Col span={12}>
            <Input
              placeholder="Limit"
              value={limit}
              type="number"
              onChange={onLimitChange}
              min={1}
              max={150}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div>efSearch:</div>
          </Col>
          <Col span={12}>
            <Input
              placeholder="efSearch"
              value={efSearch}
              type="number"
              onChange={onEfSearchChange}
              status={efSearchError ? 'error' : ''}
            />
          </Col>
        </Row>
      </div>
      <div className="evaluate-button">
        <Button
          type="primary"
          onClick={handleSubmitForEval}
          disabled={efSearchError}
          loading={loading}
        >
          Evaluate
        </Button>
      </div>
    </>
  );
};

export default RecallEvaluator;
