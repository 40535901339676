import { Button, Input, Space } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkDemoID,
  fetchDemoSettings,
  setDemoID,
  setStagingDemoID,
} from '../../slices/demoSelectorSlice';
import { AppDispatch, RootState } from '../../store/store';
import { useEffect } from 'react';
import { resetSearchSettings } from '../../slices/searchSettingsSlice';
import { resetCustomInstructions } from '../../slices/customInstructionsSlice';
import { resetSearchStyle } from '../../slices/searchStyleSlice';

import './selectDemo.css';
import { clearLiveRecommendations } from '../../slices/liveRecommendationsSlice';
import { resetResults } from '../../slices/resultsSlice';

const DemoSelector = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);
  const stagingDemoID = useSelector((state: RootState) => state.demoSelector.stagingDemoID);
  const isValidDemoId = useSelector((state: RootState) => state.demoSelector.valid);

  const updateUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('demokey', stagingDemoID);
    navigate(`?${queryParams.toString()}`, { replace: true });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const demokey = params.get('demokey');
    if (demokey) {
      dispatch(setStagingDemoID(demokey));
      dispatch(checkDemoID(demokey));
      dispatch(setDemoID(demokey));
    }
  }, [location]);

  const handleDemoKeyChange = (e: any) => {
    dispatch(setStagingDemoID(e.target.value));
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleDemoKeySubmit();
    }
  };

  const handleDemoKeySubmit = () => {
    dispatch(checkDemoID(stagingDemoID));
    dispatch(setDemoID(stagingDemoID));
    updateUrl();
  };

  useEffect(() => {
    if (isValidDemoId) {
      updateUrl();
      dispatch(fetchDemoSettings(stagingDemoID));
      dispatch(resetSearchSettings());
      dispatch(resetSearchStyle());
      dispatch(resetCustomInstructions());
      dispatch(clearLiveRecommendations());
      dispatch(resetResults());
    }
  }, [isValidDemoId, demoID]);

  useEffect(() => {});
  return (
    <div>
      <Space.Compact style={{ width: '100%' }}>
        <Input
          placeholder="Enter Demo Key"
          onChange={handleDemoKeyChange}
          onKeyDown={handleKeyPress}
          status={isValidDemoId || demoID.length === 0 ? undefined : 'error'}
          value={stagingDemoID}
        />
        <Button type="primary" onClick={handleDemoKeySubmit}>
          Submit
        </Button>
      </Space.Compact>
    </div>
  );
};

export default DemoSelector;
