import { useState, useEffect } from 'react';
import { List, Button, Modal, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './favouriteTags.css';
import { clearFavourites, removeFavourite } from '../../slices/favouritesSlice';
import { RootState } from '../../store/store';
import { AnyProduct, ImageProduct } from '../../types/types';

const FavouriteTags = () => {
  const [favClassName, setFavClassName] = useState<string>('invisible-favourites');

  const [favData, setFavData] = useState<AnyProduct[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const favourites = useSelector((state: RootState) => state.favourites.products);

  const handleRemoveFavourite = (content: AnyProduct) => {
    dispatch(removeFavourite(content));
  };

  const handleClearFavourites = () => {
    dispatch(clearFavourites());
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (favourites.length > 0) {
      setFavClassName('visible-favourites');
    } else {
      setFavClassName('invisible-favourites');
    }
    let tempFavData: AnyProduct[] = [];
    for (let i = 0; i < favourites.length; i++) {
      tempFavData.push(favourites[i]);
    }
    setFavData(tempFavData);
  }, [favourites]);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {`Edit Favourites (${favourites.length})`}
      </Button>
      <Modal
        title="Favourites"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="back" onClick={handleClearFavourites}>
            Clear All
          </Button>,
          <Button type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        {/* <div className={`favourite-tags ${favClassName}`}> */}
        <div className={`favourite-tags`}>
          {/* <div className="fav-heading">Favourites</div> */}
          <List
            itemLayout="horizontal"
            dataSource={favData}
            renderItem={(content: AnyProduct, index) => (
              <List.Item
                actions={[
                  <a
                    key="list-loadmore-edit"
                    onClick={() => {
                      handleRemoveFavourite(content);
                    }}
                  >
                    delete
                  </a>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Image
                      width={40}
                      src={
                        (content as ImageProduct).image_url !== undefined
                          ? (content as ImageProduct).image_url
                          : ''
                      }
                    />
                  }
                  title={content.name}
                />
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </>
  );
};

export default FavouriteTags;
