import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';
import ReactGA from 'react-ga4';
import './App.css';
import store from './store/store';
import DemoUI from './components/demoUI';
import AppHeader from './components/appHeader/appHeader';
import { Content } from 'antd/es/layout/layout';
import { GOOGLE_ANALYTICS_ID } from './constants';

function App() {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  return (
    <Router>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#020659',
              colorTextLightSolid: '#fff',
              borderRadius: 2,
              colorBgContainer: '#fff',
            },
          }}
        >
          <div className="App">
            <header className="App-header">
              <Layout style={{ backgroundColor: '#fff' }}>
                <AppHeader />
                <Content style={{ padding: '20px 5px' }}>
                  <DemoUI />
                </Content>
              </Layout>
            </header>
          </div>
        </ConfigProvider>
      </Provider>
    </Router>
  );
}

export default App;
