import { useState } from 'react';
import { ImageProduct } from '../../../types/types';
import { Image, Button } from 'antd';
import { HeartFilled, ShoppingCartOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { AppDispatch, RootState } from '../../../store/store';
import { setSelectedProduct, setShowRecommendations } from '../../../slices/recommendationsSlice';
import './imageProductCard.css';
import { setShowCreateStyle, setStyleReferenceProduct } from '../../../slices/customStyleSlice';
import { addClicked } from '../../../slices/liveRecommendationsSlice';
import { FALLBACK_IMAGE } from '../../../constants';

const ImageProductCard = ({
  product,
  onFavourite,
  onAddToCart,
}: {
  product: ImageProduct;
  onFavourite: (content: ImageProduct, type: string) => void;
  onAddToCart: (content: ImageProduct, type: string) => void;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { showFavourites, showCart } = useSelector(
    (state: RootState) => state.demoSelector.demoSettings,
  );

  const dispatch = useDispatch<AppDispatch>();

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const handleFavourite = (type: string) => {
    onFavourite(product, type);
  };

  const handleAddToCart = (type: string) => {
    onAddToCart(product, type);
  };

  const handleRecommend = () => {
    dispatch(setSelectedProduct(product));
    dispatch(setShowRecommendations(true));
  };

  const handleCreateStyle = () => {
    dispatch(setStyleReferenceProduct(product));
    dispatch(setShowCreateStyle(true));
    ReactGA.event('execute_create_style', {
      product_id: product.id,
      product_name: product.name,
    });
  };

  const handleImageClick = () => {
    dispatch(addClicked(product));
  };

  return (
    <div id={product.id} className={`product-card ${isLoaded ? 'loaded' : ''}`}>
      <div className="favouriting-button">
        {showFavourites && (
          <Button
            shape="circle"
            icon={<HeartFilled />}
            onClick={() => {
              handleFavourite('image');
            }}
          />
        )}
      </div>
      <div className="atc-button">
        {showCart && (
          <Button
            shape="circle"
            icon={<ShoppingCartOutlined />}
            onClick={() => {
              handleAddToCart('image');
            }}
          />
        )}
      </div>
      <div className="image-card">
        <Image
          width={320}
          height={320}
          src={product.image_url}
          alt={product.name}
          onLoad={handleImageLoad}
          onClick={handleImageClick}
          fallback={FALLBACK_IMAGE}
        />
        <div className="image-meta">
          <div className="image-title">{product.name}</div>
          {product.price && <div className="image-price">{`$${product.price}`}</div>}
        </div>
        <div className="image-actions">
          <Button
            type="primary"
            onClick={() => {
              handleRecommend();
            }}
          >
            Recommend
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleCreateStyle();
            }}
          >
            Create Style
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageProductCard;
