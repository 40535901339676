import { API_BASE_URL } from '../constants';
import { WebsiteExtractionSearchRequest, WebsiteExtractionSearchResponse } from '../types/types';

export default class WebsiteExtractionSearchService {
  private eventSource: EventSource | null = null;

  connect(
    request: WebsiteExtractionSearchRequest,
    callback: (data: WebsiteExtractionSearchResponse) => void,
  ): void {
    const params = new URLSearchParams(request as any).toString();
    const url = `${API_BASE_URL}/extract_from_website?${params}`;
    this.eventSource = new EventSource(url);

    this.eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        callback(data);
      } catch (error) {
        console.error('Error parsing SSE data:', error);
      }
    };

    this.eventSource.addEventListener('stream-end', () => {
      this.close();
    });

    this.eventSource.onerror = (error) => {
      if (this.eventSource) {
        console.error(`EventSource failed with readyState: ${this.eventSource.readyState}`);
      } else {
        console.error('EventSource failed:', error);
      }
      this.close();
    };
  }

  close(): void {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }
}
