import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { fetchProducts } from '../../slices/resultsSlice';
import { AppDispatch } from '../../store/store';
import { Button, Checkbox } from 'antd';
import ReactGA from 'react-ga4';
import './searchBar.css';
import { Filter, ScoreModifier, indexTypeEnum } from '../../types/types';
import FilterSelectorModal from '../filterSelector/filterSelectorModal';
import ScoreModifierSelectorModal from '../scoreModifierSelector/scoreModifierSelectorModal';
import { setShowLiveRecommendations } from '../../slices/liveRecommendationsSlice';
import { useNavigate, useLocation } from 'react-router-dom'; // for URL manipulation
import { LinkOutlined } from '@ant-design/icons';

type ShareSearchButtonProps = {
  query: string;
  moreOf: string;
  lessOf: string;
  filters: any[];
  scoreModifiers: any[];
  searchAsYouType: boolean;
  liveRecommendToggle: boolean;
};

const ShareSearchButton = ({
  query,
  moreOf,
  lessOf,
  filters,
  scoreModifiers,
  searchAsYouType,
  liveRecommendToggle,
}: ShareSearchButtonProps) => {
  const location = useLocation();
  const [copied, setCopied] = useState(false);

  const getURLFromSearchState = () => {
    const searchState = {
      query,
      moreOf,
      lessOf,
      filters,
      scoreModifiers,
      searchAsYouType,
      liveRecommendToggle,
    };

    const encodedState = btoa(JSON.stringify(searchState));
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('state', encodedState);

    return `${window.location.origin}${location.pathname}?${searchParams.toString()}`;
  };

  const copyShareableLink = () => {
    const url = getURLFromSearchState();
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Button onClick={copyShareableLink} type="text" icon={<LinkOutlined />}>
      {copied ? 'Copied!' : 'Copy Shareable Link'}
    </Button>
  );
};

type SearchBarProps = {
  enableMoreOf: boolean;
  enableLessOf: boolean;
  filterableAttributes: string[] | null;
  filterConfig: Record<string, { type: string; options: string[] }> | null;
  scoreModifierAttributes: string[] | null;
};

const SearchBar = ({
  enableMoreOf = true,
  enableLessOf = true,
  filterableAttributes = null,
  filterConfig = null,
  scoreModifierAttributes = null,
}: SearchBarProps) => {
  const [stateRestored, setStateRestored] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [moreOf, setMoreOf] = useState<string>('');
  const [lessOf, setLessOf] = useState<string>('');
  const [filters, setFilters] = useState<Filter[]>([]);
  const [scoreModifiers, setScoreModifiers] = useState<ScoreModifier[]>([]);
  const [searchAsYouType, setSearchAsYouType] = useState<boolean>(false);
  const [liveRecommendToggle, setLiveRecommendToggle] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const favourites = useSelector((state: RootState) => state.favourites.products);
  const customInstructions = useSelector(
    (state: RootState) => state.customInstructions.instructions,
  );
  const searchSettings = useSelector((state: RootState) => state.searchSettings);
  const { demoID, valid } = useSelector((state: RootState) => state.demoSelector);
  const style = useSelector((state: RootState) => state.searchStyle.style);
  const advancedSettings = useSelector((state: RootState) => state.advancedSettings);
  const indexType = useSelector((state: RootState) => state.demoSelector.demoSettings.indexType);

  const navigate = useNavigate();
  const location = useLocation();

  const clearSearchStateFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('state');
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    navigate(newUrl, { replace: true });
  };

  const restoreSearchStateFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const encodedState = searchParams.get('state');
    if (encodedState) {
      try {
        const decodedState = JSON.parse(atob(encodedState));
        setQuery(decodedState.query || '');
        setMoreOf(decodedState.moreOf || '');
        setLessOf(decodedState.lessOf || '');
        setFilters(decodedState.filters || []);
        setScoreModifiers(decodedState.scoreModifiers || []);
        setSearchAsYouType(decodedState.searchAsYouType || false);
        setLiveRecommendToggle(decodedState.liveRecommendToggle || false);
        return true;
      } catch (error) {
        console.error('Failed to decode search state from URL:', error);
      }
    }
    return false;
  };

  const handleSubmit = () => {
    dispatch(
      fetchProducts({
        query,
        moreOf,
        lessOf,
        filters,
        scoreModifiers,
        customInstructions,
        favourites,
        searchSettings,
        demoID,
        style,
        advancedSettings,
      }),
    );
    ReactGA.event('execute_search', {
      search_query: query,
      search_more_of: moreOf,
      search_less_of: lessOf,
      search_filters: JSON.stringify(filters),
      search_score_modifiers: JSON.stringify(scoreModifiers),
      search_custom_instructions: customInstructions,
      search_favourites: JSON.stringify(favourites),
      search_settings: JSON.stringify(searchSettings),
      search_demo_id: demoID,
      search_style: style,
      search_advanced_settings: JSON.stringify(advancedSettings),
    });
  };

  useEffect(() => {
    if (!valid) {
      return;
    }
    const wasRestored = restoreSearchStateFromURL();
    setStateRestored(wasRestored);
    clearSearchStateFromURL();
  }, [valid]);

  useEffect(() => {
    if (stateRestored && query.length > 0) {
      handleSubmit();
      setStateRestored(false);
    }
  }, [query, stateRestored]);

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const performSearch = useCallback(() => {
    if (!searchAsYouType) {
      return;
    }
    dispatch(
      fetchProducts({
        query,
        moreOf: moreOf,
        lessOf: lessOf,
        filters: filters,
        scoreModifiers: scoreModifiers,
        customInstructions: null,
        favourites: [],
        searchSettings,
        demoID,
        style,
        advancedSettings: advancedSettings,
      }),
    );
  }, [query, dispatch]);

  useEffect(() => {
    if (!searchAsYouType) {
      return;
    }
    const timer = setTimeout(() => {
      performSearch();
    }, 200);

    return () => clearTimeout(timer);
  }, [query, performSearch]);

  useEffect(() => {
    dispatch(setShowLiveRecommendations(liveRecommendToggle));
  }, [liveRecommendToggle]);

  return (
    <>
      <div className="search-bar">
        <input
          className="search-bar-input"
          type="text"
          placeholder="Search for this..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        {enableMoreOf && (
          <input
            className="search-bar-input"
            type="text"
            placeholder="More of this..."
            value={moreOf}
            onChange={(e) => setMoreOf(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        )}
        {enableLessOf && (
          <input
            className="search-bar-input"
            type="text"
            placeholder="Less of this..."
            value={lessOf}
            onChange={(e) => setLessOf(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        )}
        {filterableAttributes && filterableAttributes.length > 0 && (
          <FilterSelectorModal
            filterableAttributes={filterableAttributes}
            filterConfig={filterConfig}
            onChange={setFilters}
          />
        )}
        {scoreModifierAttributes && scoreModifierAttributes.length > 0 && (
          <ScoreModifierSelectorModal
            scoreModifierAttributes={scoreModifierAttributes}
            onChange={setScoreModifiers}
          />
        )}
        <Button className="search-button" onClick={handleSubmit} type="primary">
          Search
        </Button>
      </div>

      <Checkbox
        className="search-type-checkbox"
        onChange={(e) => setSearchAsYouType(e.target.checked)}
        checked={searchAsYouType}
      >
        Search as you type
      </Checkbox>
      {indexType === indexTypeEnum.MULTIMODAL_IMAGE_TEXT && (
        <Checkbox
          className="search-type-checkbox"
          onChange={(e) => setLiveRecommendToggle(e.target.checked)}
          checked={liveRecommendToggle}
        >
          Show live recommendations
        </Checkbox>
      )}
      <ShareSearchButton
        query={query}
        moreOf={moreOf}
        lessOf={lessOf}
        filters={filters}
        scoreModifiers={scoreModifiers}
        searchAsYouType={searchAsYouType}
        liveRecommendToggle={liveRecommendToggle}
      />
    </>
  );
};

export default SearchBar;
