import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../constants';
import { Product, LiveRecommendationsRequest } from '../types/types';

interface LiveRecommendationsState {
  clicked: Product[];
  recommendations: Product[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  showLiveRecommendations: boolean;
}

const initialState: LiveRecommendationsState = {
  clicked: [],
  recommendations: [],
  status: 'idle',
  error: null,
  showLiveRecommendations: false,
};

export const fetchLiveRecommendations = createAsyncThunk(
  'products/fetchLiveRecommendations',
  async (params: LiveRecommendationsRequest) => {
    const response = await fetch(API_BASE_URL + '/recommend_related_marqo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        itemIDs: params.itemIDs,
        demoID: params.demoID,
        limit: params.limit,
        maxItems: params.maxItems,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.results;
  },
);

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    addClicked: (state, action: PayloadAction<Product>) => {
      let clicked = [...state.clicked];
      clicked.push(action.payload);
      state.clicked = clicked;
    },
    setClicked: (state, action: PayloadAction<Product[]>) => {
      state.clicked = action.payload;
    },
    clearClicked(state) {
      state.clicked = [];
    },
    clearLiveRecommendations(state) {
      state.recommendations = [];
    },
    setShowLiveRecommendations(state, action: PayloadAction<boolean>) {
      state.showLiveRecommendations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLiveRecommendations.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.recommendations = [];
      })
      .addCase(fetchLiveRecommendations.fulfilled, (state, action: PayloadAction<Product[]>) => {
        state.status = 'succeeded';
        state.recommendations = action.payload;
        state.error = null;
      })
      .addCase(fetchLiveRecommendations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Could not fetch recommendations';
        state.recommendations = [];
      });
  },
});

export const {
  clearClicked,
  clearLiveRecommendations,
  setShowLiveRecommendations,
  setClicked,
  addClicked,
} = recommendationsSlice.actions;

export default recommendationsSlice.reducer;
