import { Button, Image, List, Modal, Space, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  addToCart,
  fetchMissingItems,
  removeFromCart,
  clearCartCompletions,
} from '../../slices/cartSlice';
import { AnyProduct, ImageProduct } from '../../types/types';

import './cart.css';

type CompletionsProps = {
  completedProducts: AnyProduct[];
  queries: string[];
  text: string;
};

const Completions = ({ completedProducts, queries, text }: CompletionsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const colors = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
  ];
  return (
    <>
      <h1>Suggested Cart Completions</h1>
      <h5>Cart completion keywords:</h5>
      <div>
        {queries.map((query, index) => (
          <Tag color={colors[index % colors.length]} key={index}>
            {query}
          </Tag>
        ))}
      </div>
      <h5>Cart completion reasoning:</h5>
      <div>{text}</div>
      <h5>Items:</h5>
      <List
        itemLayout="horizontal"
        dataSource={completedProducts}
        renderItem={(content: AnyProduct, index) => (
          <List.Item
            actions={[
              <Button
                key="list-loadmore-edit"
                onClick={() => {
                  dispatch(addToCart(content));
                }}
              >
                add to cart
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Image
                  width={40}
                  src={
                    (content as ImageProduct).image_url !== undefined
                      ? (content as ImageProduct).image_url
                      : ''
                  }
                />
              }
              title={content.name}
            />
          </List.Item>
        )}
      />
    </>
  );
};

const Cart = () => {
  const { products, queries, text, completedProducts, status } = useSelector(
    (state: RootState) => state.cart,
  );
  const searchSettings = useSelector((state: RootState) => state.searchSettings);
  const advancedSettings = useSelector((state: RootState) => state.advancedSettings);
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const handleGetCartCompletion = () => {
    dispatch(
      fetchMissingItems({
        demoID: demoID,
        products: products,
        searchSettings: searchSettings,
        advancedSettings: advancedSettings,
      }),
    );
  };

  const handleClearCartCompletions = () => {
    dispatch(clearCartCompletions());
  };

  const handleRemoveFromCart = (item: AnyProduct) => {
    dispatch(removeFromCart(item));
  };

  useEffect(() => {
    if (status === 'loading') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [status]);

  return (
    <>
      <div>
        <Button
          type="primary"
          onClick={() => {
            setOpen(true);
          }}
        >{`Cart (${products.length})`}</Button>
      </div>
      <Modal
        title="Cart"
        open={open}
        onOk={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
        width={1200}
      >
        <div className={`atc-items`}>
          <List
            itemLayout="horizontal"
            dataSource={products}
            renderItem={(content: AnyProduct, index) => (
              <List.Item
                actions={[
                  <a
                    key="list-loadmore-edit"
                    onClick={() => {
                      handleRemoveFromCart(content);
                    }}
                  >
                    delete
                  </a>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Image
                      width={40}
                      src={
                        (content as ImageProduct).image_url !== undefined
                          ? (content as ImageProduct).image_url
                          : ''
                      }
                    />
                  }
                  title={content.name}
                />
              </List.Item>
            )}
          />
        </div>
        <div className="cart-buttons">
          <Space size="middle" direction="horizontal">
            <Button
              type="primary"
              loading={loading}
              onClick={handleGetCartCompletion}
              disabled={products.length === 0}
            >
              Complete my Cart
            </Button>
            <Button
              danger
              onClick={handleClearCartCompletions}
              disabled={completedProducts.length === 0}
            >
              Clear Cart Completions
            </Button>
          </Space>
        </div>
        {completedProducts.length > 0 && (
          <Completions text={text || ''} queries={queries} completedProducts={completedProducts} />
        )}
      </Modal>
    </>
  );
};

export default Cart;
