import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImageProduct } from '../types/types';

interface ResultsState {
  showCreateStyle: boolean;
  referenceProduct: ImageProduct | null;
}

const initialState: ResultsState = {
  showCreateStyle: false,
  referenceProduct: null,
};

export const customStyleSlice = createSlice({
  name: 'customStyle',
  initialState,
  reducers: {
    setShowCreateStyle(state, action: PayloadAction<boolean>) {
      state.showCreateStyle = action.payload;
    },
    setStyleReferenceProduct(state, action: PayloadAction<ImageProduct>) {
      state.referenceProduct = action.payload;
    },
  },
});

export const { setShowCreateStyle, setStyleReferenceProduct } = customStyleSlice.actions;

export default customStyleSlice.reducer;
