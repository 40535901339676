import { useState } from 'react';
import { ImageProduct } from '../../../types/types';
import { Image, Button } from 'antd';
import { HeartFilled, ShoppingCartOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { setSelectedProduct, setShowRecommendations } from '../../../slices/recommendationsSlice';
import { setShowCreateStyle, setStyleReferenceProduct } from '../../../slices/customStyleSlice';
import ReactGA from 'react-ga4';
import './imageTile.css';
import { FALLBACK_IMAGE } from '../../../constants';
import { addClicked } from '../../../slices/liveRecommendationsSlice';

const ImageTile = ({
  product,
  onFavourite,
  onAddToCart,
  imageWidth,
}: {
  product: ImageProduct;
  onFavourite: (content: ImageProduct, type: string) => void;
  onAddToCart: (content: ImageProduct, type: string) => void;
  imageWidth: number;
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const { showFavourites, showCart } = useSelector(
    (state: RootState) => state.demoSelector.demoSettings,
  );

  const handleFavourite = (e: React.MouseEvent) => {
    e.stopPropagation();
    onFavourite(product, 'image');
  };

  const handleAddToCart = (e: React.MouseEvent) => {
    e.stopPropagation();
    onAddToCart(product, 'image');
  };

  const handleRecommend = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(setSelectedProduct(product));
    dispatch(setShowRecommendations(true));
  };

  const handleCreateStyle = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(setStyleReferenceProduct(product));
    dispatch(setShowCreateStyle(true));
    ReactGA.event('execute_create_style', {
      product_id: product.id,
      product_name: product.name,
    });
  };

  const togglePreview = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).className === 'image-tile-overlay') {
      setShowPreview(!showPreview);
      handleImageClick();
    }
  };

  const handleImageClick = () => {
    dispatch(addClicked(product));
  };

  return (
    <div className="image-tile" id={product.id}>
      <Image
        src={product.image_url}
        alt={product.name}
        width={product.width || imageWidth}
        height={product.height || Math.round(imageWidth * 0.75)}
        fallback={FALLBACK_IMAGE}
        preview={{ visible: showPreview, onVisibleChange: (visible) => setShowPreview(visible) }}
      />
      <div className="image-tile-overlay" onClick={togglePreview}>
        <div className="image-tile-meta">
          <div className="image-title">{product.name}</div>
          {product.price && <div className="image-price">{`$${product.price}`}</div>}
        </div>
        <div className="image-tile-actions">
          {showFavourites && (
            <Button shape="circle" icon={<HeartFilled />} onClick={handleFavourite} />
          )}
          {showCart && (
            <Button shape="circle" icon={<ShoppingCartOutlined />} onClick={handleAddToCart} />
          )}
          <Button type="primary" onClick={handleRecommend}>
            Recommend
          </Button>
          <Button type="primary" onClick={handleCreateStyle}>
            Create Style
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageTile;
