import React, { useState, useEffect } from 'react';
import { List, Button, Input, message } from 'antd';
import { CustomFilter } from '../../types/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const StyleManager: React.FC = () => {
  const [customFilters, setCustomFilters] = useState<CustomFilter[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editedName, setEditedName] = useState('');
  const [editedTemplate, setEditedTemplate] = useState('');

  const showCreateStyle = useSelector((state: RootState) => state.customStyle.showCreateStyle);

  useEffect(() => {
    loadStyles();
  }, [showCreateStyle]);

  useEffect(() => {
    loadStyles();
  }, []);

  const loadStyles = () => {
    const storedFilters = localStorage.getItem('customFilters');
    if (storedFilters) {
      setCustomFilters(JSON.parse(storedFilters));
    }
  };

  const saveToLocalStorage = (filters: CustomFilter[]) => {
    localStorage.setItem('customFilters', JSON.stringify(filters));
  };

  const startEdit = (index: number) => {
    setEditIndex(index);
    setEditedName(customFilters[index].name);
    setEditedTemplate(customFilters[index].customFilterTemplate);
  };

  const saveEdit = (index: number) => {
    const updatedFilters = [...customFilters];
    updatedFilters[index] = { name: editedName, customFilterTemplate: editedTemplate };
    saveToLocalStorage(updatedFilters);
    setCustomFilters(updatedFilters);
    message.success('Style updated successfully');
    cancelEdit();
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setEditedName('');
    setEditedTemplate('');
  };

  const deleteStyle = (index: number) => {
    const updatedFilters = customFilters.filter((_, i) => i !== index);
    saveToLocalStorage(updatedFilters);
    setCustomFilters(updatedFilters);
    message.success('Style deleted successfully');
  };

  return (
    <List
      dataSource={customFilters}
      renderItem={(item, index) => (
        <List.Item
          actions={
            editIndex === index
              ? [
                  <Button key="save" type="link" onClick={() => saveEdit(index)}>
                    Save
                  </Button>,
                  <Button key="cancel" type="link" onClick={cancelEdit}>
                    Cancel
                  </Button>,
                ]
              : [
                  <Button key="edit" type="link" onClick={() => startEdit(index)}>
                    Edit
                  </Button>,
                  <Button key="delete" danger onClick={() => deleteStyle(index)}>
                    Delete
                  </Button>,
                ]
          }
        >
          {editIndex === index ? (
            <>
              <Input
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                style={{ width: '150px', marginRight: '10px' }}
              />
              <Input
                value={editedTemplate}
                onChange={(e) => setEditedTemplate(e.target.value)}
                style={{ width: '400px' }}
              />
            </>
          ) : (
            `${item.name}: ${item.customFilterTemplate}`
          )}
        </List.Item>
      )}
    />
  );
};

export default StyleManager;
