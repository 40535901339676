import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useEffect, useState } from 'react';
import { ImageProduct } from '../../types/types';
import { fetchRecommendationRandomWalk } from '../../services/recommendationRandomWalkService';
import { ImageGrid } from './itemGrids';
import Loading from '../loading/loading';
import { Button, Input, Space } from 'antd';

type RecommendationWalkProps = {
  displayed: boolean;
};

const RecommendationWalk = ({ displayed }: RecommendationWalkProps) => {
  const [walk, setWalk] = useState<ImageProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [maxDepth, setMaxDepth] = useState<number>(5);
  const [maxChildren, setMaxChildren] = useState<number>(2);
  const [candidateLimit, setCandidateLimit] = useState<number>(100);
  const [candidateDiversity, setCandidateDiversity] = useState<number>(0);
  const { selectedProduct } = useSelector((state: RootState) => state.recommendations);
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);

  useEffect(() => {
    if (selectedProduct && displayed) {
      handleRecommend();
    }
  }, [selectedProduct, displayed]);

  const handleRecommend = async () => {
    if (!selectedProduct || !displayed) {
      return;
    }
    setWalk([]);
    setLoading(true);

    const fetchData = async () => {
      try {
        const walkData = (await fetchRecommendationRandomWalk({
          itemID: selectedProduct.id,
          layers: maxDepth,
          children: maxChildren,
          candidateLimit: candidateLimit,
          candidateDiversity: candidateDiversity,
          demoID: demoID,
        })) as ImageProduct[];
        setWalk(walkData);
      } catch (error) {
        console.error('Failed to fetch recommendation walk:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  };

  const handleDepthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setMaxDepth(0);
      return;
    }
    setMaxDepth(parseInt(e.target.value));
  };

  const handleChildrenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setMaxChildren(0);
      return;
    }
    setMaxChildren(parseInt(e.target.value));
  };

  const handleCandidateLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setCandidateLimit(0);
      return;
    }
    setCandidateLimit(parseInt(e.target.value));
  };

  const handleCandidateDiversityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setCandidateDiversity(0);
      return;
    }
    setCandidateDiversity(parseInt(e.target.value));
  };

  if (!displayed || !selectedProduct) {
    return <></>;
  }

  return (
    <div>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Loading />
        </div>
      )}
      <Space direction="vertical">
        <Space direction="horizontal" style={{ width: '100%', paddingTop: '10px' }}>
          <div>
            <label htmlFor="maxDepth">Max Depth:</label>
            <Input
              id="maxDepth"
              value={maxDepth}
              type="number"
              step={1}
              min={0}
              max={10}
              onChange={handleDepthChange}
            />
          </div>
          <div>
            <label htmlFor="maxChildren">Max Children:</label>
            <Input
              id="maxChildren"
              value={maxChildren}
              type="number"
              step={1}
              min={1}
              max={100}
              onChange={handleChildrenChange}
            />
          </div>
          <div>
            <label htmlFor="candidateLimit">Candidate Limit:</label>
            <Input
              id="candidateLimit"
              value={candidateLimit}
              type="number"
              step={1}
              min={0}
              max={500}
              onChange={handleCandidateLimitChange}
            />
          </div>
          <div>
            <label htmlFor="candidateDiversity">Candidate Diversity:</label>
            <Input
              id="candidateDiversity"
              value={candidateDiversity}
              type="number"
              step={1}
              min={0}
              max={100}
              onChange={handleCandidateDiversityChange}
            />
          </div>
        </Space>
        <Button id="recommendButton" type="primary" onClick={() => handleRecommend()}>
          Recommend
        </Button>
      </Space>
      <ImageGrid selectedId={selectedProduct?.id} products={walk} showRecommendButton={false} />
    </div>
  );
};

export default RecommendationWalk;
