import { useState, useEffect } from 'react';
import { ScoreModifier } from '../../types/types';
import { Button, Input, Select, Space } from 'antd';

import './scoreModifierSelector.css';

type ScoreModifierSelectorProps = {
  scoreModifierAttributes: string[];
  onChange: (scoreModifiers: ScoreModifier[]) => void;
};

type ModifierOperation = 'add_to_score' | 'multiply_score_by';

const ScoreModifierSelector = ({
  scoreModifierAttributes,
  onChange,
}: ScoreModifierSelectorProps) => {
  const [scoreModifiers, setScoreModifiers] = useState<ScoreModifier[]>([]);

  const modifiers = ['add_to_score', 'multiply_score_by'];

  useEffect(() => {
    onChange(scoreModifiers);
  }, [scoreModifiers]);

  const addScoreModifier = () => {
    let newScoreModifier: ScoreModifier = {
      attribute: 'Please select...',
      modifier: 'add_to_score',
      value: 0,
    };
    let newScoreModifiers = [...scoreModifiers, newScoreModifier];
    setScoreModifiers(newScoreModifiers);
  };

  const editScoreModifier = (
    index: number,
    attribute: string,
    modifier: ModifierOperation,
    value: number,
  ) => {
    let newScoreModifiers = [...scoreModifiers];
    newScoreModifiers[index].attribute = attribute;
    newScoreModifiers[index].modifier = modifier;
    newScoreModifiers[index].value = value;
    setScoreModifiers(newScoreModifiers);
  };

  const removeScoreModifier = (index: number) => {
    let newScoreModifier = [...scoreModifiers];
    newScoreModifier.splice(index, 1);
    setScoreModifiers(newScoreModifier);
  };

  return (
    <div className="modifier-constructor">
      <div className="modifiers">
        {scoreModifiers.map((scoreModifier, index) => (
          <div key={index} className="modifier-entry">
            <Space direction="horizontal" size="small">
              <Select
                style={{ width: 150 }}
                value={scoreModifier.attribute}
                onChange={(value) => {
                  editScoreModifier(index, value, scoreModifier.modifier, scoreModifier.value);
                }}
              >
                {scoreModifierAttributes.map((attribute) => (
                  <Select.Option key={attribute} value={attribute}>
                    {attribute}
                  </Select.Option>
                ))}
              </Select>
              <Select
                style={{ width: 150 }}
                value={scoreModifier.modifier}
                onChange={(value) => {
                  editScoreModifier(index, scoreModifier.attribute, value, scoreModifier.value);
                }}
              >
                {modifiers.map((modifier) => (
                  <Select.Option key={modifier} value={modifier}>
                    {modifier}
                  </Select.Option>
                ))}
              </Select>
              <Input
                type="number"
                value={scoreModifier.value}
                onChange={(e) => {
                  editScoreModifier(
                    index,
                    scoreModifier.attribute,
                    scoreModifier.modifier,
                    parseFloat(e.target.value),
                  );
                }}
              />
              <Button
                onClick={() => {
                  removeScoreModifier(index);
                }}
              >
                Remove
              </Button>
            </Space>
          </div>
        ))}
      </div>
      <div className="add-modifier">
        <Button onClick={addScoreModifier}>Add Score Modifier</Button>
      </div>
    </div>
  );
};

export default ScoreModifierSelector;
