import { Button, Modal } from 'antd';
import WebsiteExtraction from './websiteExtraction';
import { useState } from 'react';

const WebsiteExtractionModal = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        Website Search Extraction
      </Button>
      <Modal
        title="Website Search Extraction"
        open={open}
        onOk={handleClose}
        onCancel={handleClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1200}
      >
        <WebsiteExtraction />
      </Modal>
    </>
  );
};

export default WebsiteExtractionModal;
