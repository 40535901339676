import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyProduct } from '../types/types';

interface FavouritesState {
  products: AnyProduct[];
}

const initialState: FavouritesState = {
  products: [],
};

export const favouritesSlice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {
    addFavourite: (state, action: PayloadAction<AnyProduct>) => {
      if (!state.products.includes(action.payload)) {
        state.products.push(action.payload);
      }
    },
    removeFavourite: (state, action: PayloadAction<AnyProduct>) => {
      state.products = state.products.filter((product) => product.id !== action.payload.id);
    },
    clearFavourites: (state) => {
      state.products = [];
    },
  },
});

export const { addFavourite, removeFavourite, clearFavourites } = favouritesSlice.actions;

export default favouritesSlice.reducer;
