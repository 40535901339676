import { AudioProduct } from '../../../types/types';
import { Button } from 'antd';
import { HeartFilled, ShoppingCartOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { setSelectedProduct, setShowRecommendations } from '../../../slices/recommendationsSlice';
import './audioProductCard.css';
import { addClicked } from '../../../slices/liveRecommendationsSlice';

const AudioProductCard = ({
  product,
  onFavourite,
  onAddToCart,
}: {
  product: AudioProduct;
  onFavourite: (content: AudioProduct, type: string) => void;
  onAddToCart: (content: AudioProduct, type: string) => void;
}) => {
  const { showFavourites, showCart } = useSelector(
    (state: RootState) => state.demoSelector.demoSettings,
  );

  const dispatch = useDispatch<AppDispatch>();

  const handleFavourite = (type: string) => {
    onFavourite(product, type);
  };

  const handleAddToCart = (type: string) => {
    onAddToCart(product, type);
  };

  const handleRecommend = () => {
    dispatch(setSelectedProduct(product));
    dispatch(setShowRecommendations(true));
  };

  const handleImageClick = () => {
    dispatch(addClicked(product));
  };

  return (
    <div id={product.id} className={'audio-card'}>
      <div className="favouriting-button">
        {showFavourites && (
          <Button
            shape="circle"
            icon={<HeartFilled />}
            onClick={() => {
              handleFavourite('audio');
            }}
          />
        )}
      </div>
      <div className="atc-button">
        {showCart && (
          <Button
            shape="circle"
            icon={<ShoppingCartOutlined />}
            onClick={() => {
              handleAddToCart('audio');
            }}
          />
        )}
      </div>
      <div className="audio-card">
        <audio
          controls
          onClick={() => {
            handleImageClick();
          }}
        >
          <source src={product.audio_url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <div className="audio-meta">
          <div className="audio-title">{product.name}</div>
          {product.price && <div className="audio-price">{`$${product.price}`}</div>}
        </div>
        <div className="audio-actions">
          <Button
            type="primary"
            onClick={() => {
              handleRecommend();
            }}
          >
            Recommend
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AudioProductCard;
