import {
  ImageProduct,
  TextProduct,
  VideoProduct,
  Product,
  indexTypeEnum,
  AudioProduct,
  AnyProduct,
} from '../../types/types';
import TextProductCard from './TextProduct/textProduct';
import ImageProductCard from './ImageProduct/imageProduct';
import VideoProductCard from './VideoProduct/videoProduct';
import AudioProductCard from './AudioProduct/audioProduct';

const ProductCard = ({
  product,
  curIndexType,
  onFavourite,
  onAddToCart,
}: {
  product: Product;
  curIndexType: indexTypeEnum;
  onFavourite: (content: AnyProduct, type: string) => void;
  onAddToCart: (content: AnyProduct, type: string) => void;
}) => {
  if (curIndexType === indexTypeEnum.TEXT) {
    let textProduct = product as TextProduct;
    return TextProductCard({ product: textProduct, onFavourite });
  } else if (curIndexType === indexTypeEnum.MULTIMODAL_IMAGE_TEXT) {
    let imageProduct = product as ImageProduct;
    return ImageProductCard({ product: imageProduct, onFavourite, onAddToCart });
  } else if (curIndexType === indexTypeEnum.MULTIMODAL_VIDEO_TEXT) {
    let videoProduct = product as VideoProduct;
    return VideoProductCard({ product: videoProduct, onFavourite, onAddToCart });
  } else if (curIndexType === indexTypeEnum.MULTIMODAL_AUDIO_TEXT) {
    let audioProduct = product as AudioProduct;
    return AudioProductCard({ product: audioProduct, onFavourite, onAddToCart });
  }

  return <div />;
};

export default ProductCard;
