import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyProduct, CartCompletionRequest, CartCompletionResponse } from '../types/types';
import { API_BASE_URL } from '../constants';

interface CartState {
  products: AnyProduct[];
  completedProducts: AnyProduct[];
  queries: string[];
  text: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CartState = {
  products: [],
  completedProducts: [],
  queries: [],
  text: null,
  status: 'idle',
  error: null,
};

export const fetchMissingItems = createAsyncThunk(
  'products/fetchCartCompletions',
  async (params: CartCompletionRequest) => {
    const response = await fetch(API_BASE_URL + '/missing_items', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        demoID: params.demoID,
        products: params.products,
        searchSettings: params.searchSettings,
        advancedSettings: params.advancedSettings,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: CartCompletionResponse = await response.json();
    return data;
  },
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<AnyProduct>) => {
      if (!state.products.includes(action.payload)) {
        state.products.push(action.payload);
      }
    },
    removeFromCart: (state, action: PayloadAction<AnyProduct>) => {
      state.products = state.products.filter((product) => product.id !== action.payload.id);
    },
    clearCart: (state) => {
      state.products = [];
    },
    clearCartCompletions: (state) => {
      state.completedProducts = [];
      state.queries = [];
      state.text = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMissingItems.fulfilled, (state, action) => {
      state.completedProducts = action.payload.results;
      state.queries = action.payload.queries;
      state.text = action.payload.text;
      state.status = 'succeeded';
    });
    builder.addCase(fetchMissingItems.pending, (state) => {
      state.status = 'loading';
      state.completedProducts = [];
      state.queries = [];
      state.text = null;
      state.error = null;
    });
    builder.addCase(fetchMissingItems.rejected, (state, action) => {
      state.status = 'failed';
      state.completedProducts = [];
      state.queries = [];
      state.text = null;
      state.error = action.error.message || 'Unknown error';
    });
  },
});

export const { addToCart, removeFromCart, clearCart, clearCartCompletions } = cartSlice.actions;

export default cartSlice.reducer;
