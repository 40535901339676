import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb, { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { Modal, Segmented, Space } from 'antd';
import { AppDispatch, RootState } from '../../store/store';
import { AppstoreFilled, ApartmentOutlined, ForkOutlined } from '@ant-design/icons';
import {
  clearRecommendations,
  setSelectedProduct,
  setShowRecommendations,
} from '../../slices/recommendationsSlice';
import { Product, indexTypeEnum } from '../../types/types';
import './recommendationModal.css';
import RecommendationTree from './recommendationTree';
import RecommendationPanel from './recommendationPanel';
import RecommendationWalk from './recommendationWalk';

const RecommendationModal = () => {
  const [history, setHistory] = useState<BreadcrumbItemType[]>([]);
  const [recommendationStyle, setRecommendationStyle] = useState<string | number>('grid');
  const recommendations = useSelector((state: RootState) => state.recommendations);
  const indexType = useSelector((state: RootState) => state.demoSelector.demoSettings.indexType);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (indexType === indexTypeEnum.TEXT) {
      setRecommendationStyle('grid');
    }
  }, [indexType]);

  const handleClose = () => {
    dispatch(clearRecommendations());
    dispatch(setShowRecommendations(false));
    setHistory([]);
  };

  const handleChangeBreadcrumb = (newProduct: Product | null) => {
    if (newProduct !== null) {
      const existingIndex = history.findIndex((item) => item.key === newProduct.id);

      if (existingIndex !== -1) {
        const newHistory = history.slice(0, existingIndex + 1);
        setHistory(newHistory);
      } else if (newProduct.id !== history[history.length - 1]?.key) {
        const newHistory = [...history];
        newHistory.push({
          key: newProduct.id,
          title: (
            <a
              onClick={() => {
                dispatch(setSelectedProduct(newProduct));
              }}
            >
              {newProduct.name}
            </a>
          ),
        });
        setHistory(newHistory);
      }
    }
  };

  useEffect(() => {
    handleChangeBreadcrumb(recommendations.selectedProduct);
  }, [recommendations.selectedProduct]);

  return (
    <Modal
      title="Recommendations"
      open={recommendations.showRecommendations}
      onOk={handleClose}
      onCancel={handleClose}
      onClose={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={1200}
    >
      <Space direction="vertical">
        <Breadcrumb items={history} />
        {indexType === indexTypeEnum.MULTIMODAL_IMAGE_TEXT && (
          <Segmented
            options={[
              {
                label: 'Recommendation Grid',
                value: 'grid',
                icon: <AppstoreFilled />,
              },
              {
                label: 'Recommendation Tree',
                value: 'tree',
                icon: <ApartmentOutlined />,
              },
              {
                label: 'Random Walk',
                value: 'walk',
                icon: <ForkOutlined />,
              },
            ]}
            value={recommendationStyle}
            onChange={setRecommendationStyle}
          />
        )}
      </Space>
      <RecommendationPanel displayed={recommendationStyle === 'grid'} />
      <RecommendationTree displayed={recommendationStyle === 'tree'} />
      <RecommendationWalk displayed={recommendationStyle === 'walk'} />
    </Modal>
  );
};

export default RecommendationModal;
