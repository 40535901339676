import { Avatar, Button, Image, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
  AudioProduct,
  Conversation,
  ImageProduct,
  indexTypeEnum,
  Message,
  Product,
  TextProduct,
  VideoProduct,
} from '../../types/types';
import { fetchChatMessage } from '../../services/chatService';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import blueHippo from '../../assets/blue_hippo.png';

import './chat.css';

const { TextArea } = Input;

const InlineProductDisplay = ({
  products,
  type,
}: {
  products: Product[];
  type: 'image' | 'text' | 'audio' | 'video';
}) => {
  if (!products) return null;
  if (products.length === 0) return null;

  if (type === 'image') {
    return (
      <div className="product-display">
        {(products as ImageProduct[]).map((product, idx) => (
          <div key={idx} className="product-image">
            <Image src={product.image_url} alt={product.name} width={200} />
            <div>{product.name}</div>
          </div>
        ))}
      </div>
    );
  }

  if (type === 'video') {
    return (
      <div className="product-display">
        {(products as VideoProduct[]).map((product, idx) => (
          <div key={idx} className="product-video">
            <video width="200" controls>
              <source src={product.video_url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div>{product.name}</div>
          </div>
        ))}
      </div>
    );
  }

  if (type === 'audio') {
    return (
      <div className="product-display">
        {(products as AudioProduct[]).map((product, idx) => (
          <div key={idx} className="product-audio">
            <audio controls>
              <source src={product.audio_url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            <div>{product.name}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="product-display">
      {(products as TextProduct[]).map((product, idx) => (
        <div key={idx} className="product-text">
          <div>{product.name}</div>
          <div>{product.description}</div>
        </div>
      ))}
    </div>
  );
};

interface ChatMessageProps extends Message {
  indexType: 'image' | 'text' | 'audio' | 'video';
}

const ChatMessage = ({ role, message, products, indexType }: ChatMessageProps) => {
  let avatar;
  if (role === 'model') {
    avatar = <Avatar style={{ backgroundColor: '#87d068' }} src={blueHippo} />;
  } else {
    avatar = <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />;
  }

  return (
    <div className={`${role === 'model' ? 'agent-message' : 'user-message'} message`}>
      <div className="message-contents">
        {avatar}
        <div>{message}</div>
      </div>
      {products !== undefined && <InlineProductDisplay products={products} type={indexType} />}
    </div>
  );
};

const Chat = () => {
  const [agentTyping, setAgentTyping] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [conversation, setConversation] = useState<Conversation>({
    messages: [],
  });
  const [error, setError] = useState<boolean>(false);
  const [dots, setDots] = useState<string>('.'); // Dots for agent typing animation
  const [messageProductType, setMessageProductType] = useState<
    'image' | 'text' | 'audio' | 'video'
  >('text');
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);
  const indexType = useSelector((state: RootState) => state.demoSelector.demoSettings.indexType);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (indexType == indexTypeEnum.MULTIMODAL_IMAGE_TEXT) {
      setMessageProductType('image');
    } else if (indexType == indexTypeEnum.MULTIMODAL_VIDEO_TEXT) {
      setMessageProductType('video');
    } else if (indexType == indexTypeEnum.TEXT) {
      setMessageProductType('text');
    } else if (indexType == indexTypeEnum.MULTIMODAL_AUDIO_TEXT) {
      setMessageProductType('audio');
    }
  }, [indexType]);

  useEffect(() => {
    const fetchAgentResponse = async () => {
      if (
        conversation.messages.length === 0 ||
        conversation.messages[conversation.messages.length - 1].role !== 'user'
      ) {
        return;
      }

      setAgentTyping(true);
      setError(false);
      try {
        const response = await fetchChatMessage({ conversation: conversation, demoID: demoID });
        setConversation((prevConversation) => ({
          ...prevConversation,
          messages: [...prevConversation.messages, response],
        }));
      } catch (err) {
        setError(true);
      } finally {
        setAgentTyping(false);
      }
    };

    fetchAgentResponse();
  }, [conversation.messages]);

  useEffect(() => {
    scrollToBottom();
  }, [conversation.messages, agentTyping]);

  useEffect(() => {
    if (!agentTyping) return;

    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots === '...' ? '.' : prevDots + '.'));
    }, 500);

    return () => clearInterval(interval);
  }, [agentTyping]);

  const handleSend = () => {
    if (!message.trim() || error) return;
    setConversation((prevConversation) => ({
      messages: [...prevConversation.messages, { role: 'user', message }],
    }));
    setMessage('');
  };

  const handleTyping = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleClear = () => {
    setConversation({ messages: [] });
    setAgentTyping(false);
    setError(false);
  };

  useEffect(() => {
    handleClear();
  }, [demoID]);

  return (
    <div>
      <div className="chat-controls">
        <Button type="primary" onClick={handleClear}>
          Clear Chat
        </Button>
      </div>
      <div className="chat-messages">
        {conversation.messages.map((msg, idx) => (
          <ChatMessage
            key={idx}
            role={msg.role}
            message={msg.message}
            products={msg.products}
            indexType={messageProductType}
          />
        ))}
        {agentTyping && <div className="agent-typing">Agent is typing{dots}</div>}
        {error && (
          <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>
            Server error. Please clear the chat and try again.
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="message-input">
        <TextArea
          placeholder="Type your message here..."
          rows={4}
          value={message}
          onChange={handleTyping}
          onKeyDown={handleKeyDown}
          disabled={error}
        />
        <Button
          type="primary"
          onClick={handleSend}
          disabled={message.trim() === '' || agentTyping || error}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default Chat;
